import React, { useContext } from "react";
import classNames from "classnames";
import styles from "./nav-bar.styles.module.scss";
import { useTranslation } from "react-i18next";
import { TeamManagementAppContext } from "../../app.context";
import { TeamManagementTabs } from "../../lib";
import { TypographyComponent } from "gx-npm-ui";

const TeamManagementNavBar = () => {
  const { t } = useTranslation();
  const { selectedTab, setSelectedTab } = useContext(TeamManagementAppContext);
  return (
    <section
      aria-label={
        selectedTab === TeamManagementTabs.SCORING_ASSIGNMENTS
          ? t("Scoring assignments is selected")
          : t("Team management is selected")
      }
      className={classNames(styles.navContainer)}
    >
      <button
        id="teams"
        onClick={() => {
          setSelectedTab(TeamManagementTabs.TEAM_MANAGEMENT);
        }}
        className={classNames(
          styles.libraryNavItem,
          selectedTab === TeamManagementTabs.TEAM_MANAGEMENT && styles.isSelected
        )}
        tabIndex={0}
        aria-label={"teams-btn"}
      >
        <TypographyComponent
          color={selectedTab === TeamManagementTabs.TEAM_MANAGEMENT ? "yam" : "carbon"}
          styling={selectedTab === TeamManagementTabs.TEAM_MANAGEMENT ? "p3" : "p4"}
          boldness={"medium"}
        >
          {t("Team management")}
        </TypographyComponent>
      </button>
      <button
        id="scoring"
        onClick={() => {
          setSelectedTab(TeamManagementTabs.SCORING_ASSIGNMENTS);
        }}
        className={classNames(
          "scoring-assignments",
          "p3 semi-bold",
          styles.libraryNavItem,
          selectedTab === TeamManagementTabs.SCORING_ASSIGNMENTS && styles.isSelected
        )}
        tabIndex={0}
        aria-label={"scoring-btn"}
      >
        <TypographyComponent
          color={selectedTab === TeamManagementTabs.SCORING_ASSIGNMENTS ? "yam" : "carbon"}
          styling={selectedTab === TeamManagementTabs.SCORING_ASSIGNMENTS ? "p3" : "p4"}
          boldness={"medium"}
        >
          {t("Scoring assignments")}
        </TypographyComponent>
      </button>
    </section>
  );
};

export default TeamManagementNavBar;
