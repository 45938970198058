import React from "react";
import { Button, Dialog, TypographyComponent, useFeatureFlag } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import styles from "./dialog-user-role-change.styles.module.scss";
import classNames from "classnames";
import { GCOM_2917_GCOM_2989__userAccessUpdateCopyChange } from "../../lib/feature-flags";

type DialogUserRoleChangeProps = {
  confirmText: string;
  isOpen: boolean;
  name: string;
  onCancel?: (_e: React.MouseEvent<HTMLButtonElement>) => void;
  onConfirm?: (_e: React.MouseEvent<HTMLButtonElement>) => void;
  title: string;
  userRole: string;
};

const DialogUserRoleChange: React.FC<DialogUserRoleChangeProps> = ({
  confirmText = "",
  isOpen = false,
  name = "",
  onCancel = (_e) => {},
  onConfirm = (_e) => {},
  title = "",
  userRole = "",
}) => {
  const { t } = useTranslation();
  const isGCOM2989FFOn = useFeatureFlag(GCOM_2917_GCOM_2989__userAccessUpdateCopyChange);
  const handleConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
    onConfirm(e);
  };
  const userRoleText = userRole.toLowerCase();

  return (
    <Dialog
      rootClassName={classNames(styles.root, "gx-role-change-dialog")}
      maxDialogWidth={"600px"}
      open={isOpen}
      title={title}
      body={
        <TypographyComponent styling={"p2"} color={"coal"}>
          {t("Changing")}{" "}
          <TypographyComponent element={"span"} boldness={"semi"} color={"coal"}>
            {name}’s
          </TypographyComponent>{" "}
          {t("role from")}
          <TypographyComponent
            element={"span"}
            boldness={"semi"}
            color={"coal"}
          >{` ${userRoleText}`}</TypographyComponent>{" "}
          {t("to")}
          <TypographyComponent element={"span"} boldness={"semi"} color={"coal"}>
            {" "}
            {t("viewer ")}
          </TypographyComponent>
          {isGCOM2989FFOn
            ? t("will delete any scores they have already entered and remove any checklist assignments they have.")
            : t("will delete any scores they have already entered.")}
        </TypographyComponent>
      }
      footer={
        <div className={styles.footerContainer}>
          <Button ariaLabel={t("button cancel change user role")} onClick={onCancel} rootClassName="btn-tertiary">
            {t("CANCEL")}
          </Button>
          <Button
            ariaLabel={t("button confirm change user role")}
            onClick={handleConfirm}
            rootClassName="primary-destructive-btn"
          >
            {confirmText}
          </Button>
        </div>
      }
    />
  );
};

export default DialogUserRoleChange;
