// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.P19NNeOXnPkS3hCsZTx_ .xhGb5zlkZyy7kVR9KOam a{color:var(--color-status-poison-cherry)}.P19NNeOXnPkS3hCsZTx_ .MuiSnackbar-anchorOriginBottomLeft{left:unset;right:42px;bottom:unset}.P19NNeOXnPkS3hCsZTx_ .MuiSnackbar-root{overflow:hidden;position:absolute}.wf2yNbsqZ17EThS_PWNI{display:flex;justify-content:flex-end;padding-top:20px}.wf2yNbsqZ17EThS_PWNI button:last-child{margin-left:12px}`, "",{"version":3,"sources":["webpack://./src/sections/team-management/dialog-user-role-change.styles.module.scss"],"names":[],"mappings":"AAAA,8CACE,uCAAA,CAGF,0DACE,UAAA,CACA,UAAA,CACA,YAAA,CAGF,wCACE,eAAA,CACA,iBAAA,CAGF,sBACE,YAAA,CACA,wBAAA,CACA,gBAAA,CAGF,wCACC,gBAAA","sourcesContent":[".root .poison-cherry a {\n  color: var(--color-status-poison-cherry);\n}\n\n.root :global(.MuiSnackbar-anchorOriginBottomLeft) {\n  left: unset;\n  right: 42px;\n  bottom: unset;\n}\n\n.root :global(.MuiSnackbar-root) {\n  overflow: hidden;\n  position: absolute;\n}\n\n.footerContainer {\n  display: flex;\n  justify-content: flex-end;\n  padding-top: 20px;\n}\n\n.footerContainer button:last-child {\n margin-left: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `P19NNeOXnPkS3hCsZTx_`,
	"poison-cherry": `xhGb5zlkZyy7kVR9KOam`,
	"footerContainer": `wf2yNbsqZ17EThS_PWNI`
};
export default ___CSS_LOADER_EXPORT___;
