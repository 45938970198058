// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.B0wTNc15ktLBRKi5rXfr{display:flex;flex-direction:column;left:56px;padding-bottom:24px;position:absolute}.B0wTNc15ktLBRKi5rXfr :hover{color:var(--color-brand-yam);cursor:pointer}.B0wTNc15ktLBRKi5rXfr button{display:flex;background-color:var(--color-white);border-top:none;border-bottom:none;border-right:none;outline:none}.Q2jU8w58iiCbMewBrhAd{border-left:solid 4px var(--color-neutrals-silver);color:var(--color-neutrals-carbon);cursor:pointer;height:40px;padding-left:24px;padding-top:8px}.Q2jU8w58iiCbMewBrhAd.WXczbJK3MKiMIFii9lXE{border-left:solid 4px var(--color-brand-yam)}`, "",{"version":3,"sources":["webpack://./src/sections/nav-bar/nav-bar.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,SAAA,CACA,mBAAA,CACA,iBAAA,CAEA,6BACE,4BAAA,CACA,cAAA,CAIJ,6BACE,YAAA,CACA,mCAAA,CACA,eAAA,CACA,kBAAA,CACA,iBAAA,CACA,YAAA,CAGF,sBACE,kDAAA,CACA,kCAAA,CACA,cAAA,CACA,WAAA,CACA,iBAAA,CACA,eAAA,CAGF,2CACE,4CAAA","sourcesContent":[".navContainer {\n  display: flex;\n  flex-direction: column;\n  left: 56px;\n  padding-bottom: 24px;\n  position: absolute;\n\n  :hover {\n    color: var(--color-brand-yam);\n    cursor: pointer;\n  }\n}\n\n.navContainer button {\n  display: flex;\n  background-color: var(--color-white);\n  border-top: none;\n  border-bottom: none;\n  border-right: none;\n  outline: none;\n}\n\n.libraryNavItem {\n  border-left: solid 4px var(--color-neutrals-silver);\n  color: var(--color-neutrals-carbon);\n  cursor: pointer;\n  height: 40px;\n  padding-left: 24px;\n  padding-top: 8px;\n}\n\n.libraryNavItem.isSelected {\n  border-left: solid 4px var(--color-brand-yam);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navContainer": `B0wTNc15ktLBRKi5rXfr`,
	"libraryNavItem": `Q2jU8w58iiCbMewBrhAd`,
	"isSelected": `WXczbJK3MKiMIFii9lXE`
};
export default ___CSS_LOADER_EXPORT___;
